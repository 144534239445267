// src/components/DataIntegrationPage.tsx
import React, { useEffect } from 'react';

import { motion } from 'framer-motion';
import { 
  DatabaseIcon, 
  ArrowRightIcon, 
  RefreshCcwIcon, 
  ShieldCheckIcon, 
  ZapIcon, 
  SettingsIcon 
} from 'lucide-react';
import { Header } from './Header';
import { ConnectorDiagram } from './diagrams/ConnectorDiagram';
import { TransformDiagram } from './diagrams/TransformDiagram';
import { SyncDiagram } from './diagrams/SyncDiagram';

export const DataIntegrationPage: React.FC = () => {
    useEffect(() => {
        // Scrolla sempre in alto quando la pagina viene caricata
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="bg-gray-900 min-h-screen text-white">
        <Header/>
    <main className="w-full mx-auto p-6 pt-24">
    <div className="min-h-screen bg-gray-900">

      {/* Hero Section with Gradient */}
      <section className="relative overflow-hidden py-20">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Data Integration
              <span className="block text-2xl md:text-3xl mt-2 text-gray-300">
                Seamlessly Connect Your Data Ecosystem
              </span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Transform your data infrastructure with our end-to-end integration platform. 
              From source to destination, we handle every step of the data journey.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Process Steps */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="space-y-24">
            {/* Step 1: Connect */}
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="grid md:grid-cols-2 gap-12 items-center"
            >
              <div>
                <div className="flex items-center space-x-2 text-blue-400 mb-4">
                  <DatabaseIcon className="w-6 h-6" />
                  <span className="text-sm font-semibold uppercase tracking-wide">Step 1</span>
                </div>
                <h2 className="text-3xl font-bold text-white mb-4">Connect Your Data Sources</h2>
                <p className="text-gray-300 text-lg leading-relaxed mb-6">
                  Choose from our extensive library of pre-built connectors. 
                  We support major databases, SaaS applications, and file formats.
                </p>
                <ul className="space-y-3 text-gray-300">
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-blue-400" />
                    No-code connector configuration
                  </li>
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-blue-400" />
                    Secure credential management
                  </li>
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-blue-400" />
                    Automatic schema detection
                  </li>
                </ul>
              </div>
              <div className="bg-gray-800/50 rounded-2xl p-6 border border-gray-700">
                {/* Add visual representation or code example */}
                <div className="bg-gray-800/50 rounded-2xl p-6 border border-gray-700">
                    <ConnectorDiagram />
                </div>
              </div>
            </motion.div>

            {/* Step 2: Transform */}
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="grid md:grid-cols-2 gap-12 items-center md:transform md:-translate-y-12"
            >
              <div className="md:order-2">
                <div className="flex items-center space-x-2 text-purple-400 mb-4">
                  <RefreshCcwIcon className="w-6 h-6" />
                  <span className="text-sm font-semibold uppercase tracking-wide">Step 2</span>
                </div>
                <h2 className="text-3xl font-bold text-white mb-4">Transform & Clean</h2>
                <p className="text-gray-300 text-lg leading-relaxed mb-6">
                  Apply powerful transformations to your data with our intuitive interface. 
                  Ensure data quality and consistency across all your systems.
                </p>
                <ul className="space-y-3 text-gray-300">
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-purple-400" />
                    Visual transformation builder
                  </li>
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-purple-400" />
                    Real-time data preview
                  </li>
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-purple-400" />
                    Data quality validation
                  </li>
                </ul>
              </div>
              <div className="bg-gray-800/50 rounded-2xl p-6 border border-gray-700 md:order-1">
                {/* Add visual representation or code example */}
                <div className="bg-gray-800/50 rounded-2xl p-6 border border-gray-700">
                    <TransformDiagram />
                </div>
              </div>
            </motion.div>

            {/* Step 3: Sync */}
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="grid md:grid-cols-2 gap-12 items-center"
            >
              <div>
                <div className="flex items-center space-x-2 text-green-400 mb-4">
                  <ZapIcon className="w-6 h-6" />
                  <span className="text-sm font-semibold uppercase tracking-wide">Step 3</span>
                </div>
                <h2 className="text-3xl font-bold text-white mb-4">Sync & Monitor</h2>
                <p className="text-gray-300 text-lg leading-relaxed mb-6">
                  Set up automated syncs with flexible scheduling options. 
                  Monitor your data pipelines and get instant alerts if something needs attention.
                </p>
                <ul className="space-y-3 text-gray-300">
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-green-400" />
                    Flexible scheduling options
                  </li>
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-green-400" />
                    Real-time monitoring
                  </li>
                  <li className="flex items-center">
                    <ArrowRightIcon className="w-5 h-5 mr-2 text-green-400" />
                    Error handling
                  </li>
                </ul>
              </div>
              <div className="bg-gray-800/50 rounded-2xl p-6 border border-gray-700">
                {/* Add visual representation or code example */}
                <div className="bg-gray-800/50 rounded-2xl p-6 border border-gray-700">
                    <SyncDiagram />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-20 bg-gray-800/30">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold text-white mb-4">
              Enterprise-Grade Features
            </h2>
            <p className="text-gray-300 text-lg max-w-3xl mx-auto">
              Built for scale, security, and reliability. Our platform provides all the features
              you need for robust data integration.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: ShieldCheckIcon,
                title: "Security First",
                description: "End-to-end encryption and comprehensive security controls"
              },
              {
                icon: SettingsIcon,
                title: "Fully Automated",
                description: "Set it and forget it - we handle the complexities"
              },
              {
                icon: DatabaseIcon,
                title: "Data Governance",
                description: "Complete visibility and control over your data flows"
              }
              // Add more features as needed
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="p-6 bg-gray-800/50 rounded-xl border border-gray-700"
              >
                <feature.icon className="w-12 h-12 text-blue-400 mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl p-12 text-center"
          >
            <h2 className="text-3xl font-bold text-white mb-4">
              Ready to Transform Your Data Infrastructure?
            </h2>
            <p className="text-gray-300 text-lg mb-8 max-w-2xl mx-auto">
              Start integrating your data sources today and unlock the full potential of your data.
            </p>
            <button className="px-8 py-4 bg-white text-gray-900 rounded-full font-medium hover:bg-white/90 transition-all transform hover:scale-105">
              Get Started Free
            </button>
          </motion.div>
        </div>
      </section>
    </div>
    </main>
    </div>
  );
};