import { motion } from 'framer-motion';
import { Header } from './Header';
import React, { useEffect } from 'react';


export const CareersPage: React.FC = () => {
  useEffect(() => {
    // Scrolla sempre in alto quando la pagina viene caricata
    window.scrollTo(0, 0);
  }, []);
    return (
      <div className="bg-gray-900 min-h-screen text-white">
          <Header/>
        <main className="w-full mx-auto p-6 pt-12"></main>
        <div className="min-h-screen bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="max-w-3xl mx-auto text-center"
            >
              <h1 className="text-4xl font-bold text-white mb-6">
                Join Our Journey
              </h1>
              <p className="text-xl text-gray-300 mb-8">
                We're a startup on a mission to revolutionize how businesses handle their data infrastructure. If you're passionate about data, technology, and innovation, we want to hear from you.
              </p>
              <div className="bg-gray-800/50 rounded-xl border border-gray-700 p-8 text-left">
                <h2 className="text-2xl font-bold text-white mb-4">
                  We're Growing!
                </h2>
                <p className="text-gray-300 mb-6">
                  As a startup in our early stages, we're always looking for talented individuals who want to make a real impact. If you're excited about building the future of data infrastructure and aren't afraid of challenges, this might be the adventure you're looking for.
                </p>
                <div className="space-y-4">
                  <p className="text-gray-300">
                    We value:
                  </p>
                  <ul className="list-disc list-inside text-gray-300 space-y-2">
                    <li>Innovation and creative problem-solving</li>
                    <li>Passion for data and technology</li>
                    <li>Entrepreneurial spirit</li>
                    <li>Collaborative mindset</li>
                  </ul>
                </div>
                <div className="mt-8 p-6 bg-gray-900/50 rounded-lg">
                  <p className="text-gray-300 text-lg">
                    Interested in joining our team? We'd love to hear from you!
                  </p>
                  <a 
                    href="mailto:wagane.diame@gmail.com"
                    className="inline-block mt-4 px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
                  >
                    Get in Touch →
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    );
  };