// src/components/DataTransformation.tsx
import React, { useState } from 'react';
import { Toggle, Slider, Select } from '../components/ui';

interface DataTransformationProps {
  sourceData?: any;
  destinationType?: string;
  onChange?: (config: TransformationConfig) => void;
}

interface Column {
  name: string;
  type: string;
}

const mockColumns: Column[] = [
  { name: 'id', type: 'number' },
  { name: 'name', type: 'string' },
  { name: 'email', type: 'string' },
  { name: 'created_at', type: 'date' },
  { name: 'amount', type: 'number' },
  { name: 'status', type: 'string' }
];

interface TransformationConfig {
  removeDuplicates: boolean;
  currencyConversion: {
    enabled: boolean;
    from: string;
    to: string;
    columns: string[];
  };
  dateFormat: {
    enabled: boolean;
    format: string;
    columns: string[];
  };
  nullHandling: {
    enabled: boolean;
    method: 'remove' | 'fill_zero' | 'fill_mean' | 'fill_custom';
    customValue?: string;
  };
  dataType: {
    enabled: boolean;
    columns: Record<string, string>;
  };
  renameColumns: {
    enabled: boolean;
    mappings: Record<string, string>;
  };
  roundNumbers: {
    enabled: boolean;
    decimals: number;
    columns: string[];
  };
  stringOperations: {
    enabled: boolean;
    operations: Array<{
      column: string;
      type: 'uppercase' | 'lowercase' | 'trim' | 'replace';
      value?: string;
    }>;
  };
}

export const DataTransformation: React.FC<DataTransformationProps> = ({
  sourceData,
  destinationType,
  onChange
}) => {
  const [config, setConfig] = useState<TransformationConfig>({
    removeDuplicates: false,
    currencyConversion: {
      enabled: false,
      from: 'USD',
      to: 'EUR',
      columns: []
    },
    dateFormat: {
      enabled: false,
      format: 'YYYY-MM-DD',
      columns: []
    },
    nullHandling: {
      enabled: false,
      method: 'remove'
    },
    dataType: {
      enabled: false,
      columns: {}
    },
    renameColumns: {
      enabled: false,
      mappings: {}
    },
    roundNumbers: {
      enabled: false,
      decimals: 2,
      columns: []
    },
    stringOperations: {
      enabled: false,
      operations: []
    }
  });

  const handleNestedToggleChange = (
    parentKey: keyof TransformationConfig,
    value: boolean
  ) => {
    setConfig(prev => {
      const parentValue = prev[parentKey];
      if (typeof parentValue === 'object' && parentValue !== null && 'enabled' in parentValue) {
        return {
          ...prev,
          [parentKey]: {
            ...(parentValue as object),
            enabled: value
          }
        };
      }
      return {
        ...prev,
        [parentKey]: value
      };
    });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold mb-6">Data Transformation</h2>
      
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Remove Duplicates */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between">
              <label className="font-medium">Remove Duplicates</label>
              <Toggle
                checked={config.removeDuplicates}
                onChange={(value: boolean) => handleNestedToggleChange('removeDuplicates', value)}
              />
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Remove duplicate rows from the dataset
            </p>
          </div>

          {/* Currency Conversion - con selezione colonne */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">Currency Conversion</label>
              <Toggle
                checked={config.currencyConversion.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('currencyConversion', value)}
              />
            </div>
            {config.currencyConversion.enabled && (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-2">
                  <Select
                    value={config.currencyConversion.from}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      currencyConversion: { ...prev.currencyConversion, from: value }
                    }))}
                    options={[
                      { label: 'USD', value: 'USD' },
                      { label: 'EUR', value: 'EUR' },
                      { label: 'GBP', value: 'GBP' }
                    ]}
                    label="From"
                  />
                  <Select
                    value={config.currencyConversion.to}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      currencyConversion: { ...prev.currencyConversion, to: value }
                    }))}
                    options={[
                      { label: 'USD', value: 'USD' },
                      { label: 'EUR', value: 'EUR' },
                      { label: 'GBP', value: 'GBP' }
                    ]}
                    label="To"
                  />
                </div>
                {/* Selezione colonne per Currency */}
                <div>
                  <label className="text-sm text-gray-500">Apply to columns</label>
                  <Select
                    value={config.currencyConversion.columns[0] || ''}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      currencyConversion: {
                        ...prev.currencyConversion,
                        columns: [value]
                      }
                    }))}
                    options={mockColumns
                      .filter(col => col.type === 'number')
                      .map(col => ({
                        label: col.name,
                        value: col.name
                      }))}
                    label="Select columns"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Date Format - con selezione colonne */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">Date Format</label>
              <Toggle
                checked={config.dateFormat.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('dateFormat', value)}
              />
            </div>
            {config.dateFormat.enabled && (
              <div className="space-y-4">
                <Select
                  value={config.dateFormat.format}
                  onChange={(value: string) => setConfig(prev => ({
                    ...prev,
                    dateFormat: { ...prev.dateFormat, format: value }
                  }))}
                  options={[
                    { label: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
                    { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
                    { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' }
                  ]}
                  label="Format"
                />
                {/* Selezione colonne per Date */}
                <div>
                  <label className="text-sm text-gray-500">Apply to columns</label>
                  <Select
                    value={config.dateFormat.columns[0] || ''}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      dateFormat: {
                        ...prev.dateFormat,
                        columns: [value]
                      }
                    }))}
                    options={mockColumns
                      .filter(col => col.type === 'date')
                      .map(col => ({
                        label: col.name,
                        value: col.name
                      }))}
                    label="Select columns"
                  />
                </div>
              </div>
            )}
          </div>

	  {/* Null Handling */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">Handle Null Values</label>
              <Toggle
                checked={config.nullHandling.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('nullHandling', value)}
              />
            </div>
            {config.nullHandling.enabled && (
              <Select
                value={config.nullHandling.method}
                onChange={(value: string) => setConfig(prev => ({
                  ...prev,
                  nullHandling: { ...prev.nullHandling, method: value as any }
                }))}
                options={[
                  { label: 'Remove rows', value: 'remove' },
                  { label: 'Fill with zero', value: 'fill_zero' },
                  { label: 'Fill with mean', value: 'fill_mean' },
                  { label: 'Custom value', value: 'fill_custom' }
                ]}
                label="Method"
              />
            )}
          </div>

          {/* Round Numbers - con selezione colonne */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">Round Numbers</label>
              <Toggle
                checked={config.roundNumbers.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('roundNumbers', value)}
              />
            </div>
            {config.roundNumbers.enabled && (
              <div className="space-y-4">
                <Slider
                  min={0}
                  max={6}
                  value={config.roundNumbers.decimals}
                  onChange={(value: number) => setConfig(prev => ({
                    ...prev,
                    roundNumbers: { ...prev.roundNumbers, decimals: value }
                  }))}
                  label="Decimal places"
                />
                {/* Selezione colonne per Round Numbers */}
                <div>
                  <label className="text-sm text-gray-500">Apply to columns</label>
                  <Select
                    value={config.roundNumbers.columns[0] || ''}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      roundNumbers: {
                        ...prev.roundNumbers,
                        columns: [value]
                      }
                    }))}
                    options={mockColumns
                      .filter(col => col.type === 'number')
                      .map(col => ({
                        label: col.name,
                        value: col.name
                      }))}
                    label="Select columns"
                  />
                </div>
              </div>
            )}
          </div>

          {/* String Operations */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">String Operations</label>
              <Toggle
                checked={config.stringOperations.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('stringOperations', value)}
              />
            </div>
            {config.stringOperations.enabled && (
              <Select
                value={config.stringOperations.operations[0]?.type || 'uppercase'}
                onChange={(value: string) => setConfig(prev => ({
                  ...prev,
                  stringOperations: {
                    ...prev.stringOperations,
                    operations: [{ column: '', type: value as any }]
                  }
                }))}
                options={[
                  { label: 'Convert to uppercase', value: 'uppercase' },
                  { label: 'Convert to lowercase', value: 'lowercase' },
                  { label: 'Trim whitespace', value: 'trim' },
                  { label: 'Replace text', value: 'replace' }
                ]}
                label="Operation"
              />
            )}
          </div>
        </div>

        {/* Preview Button */}
        <div className="flex justify-end mt-6">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            onClick={() => {/* Handle preview */}}
          >
            Preview Transformation
          </button>
        </div>
      </div>
    </div>
  );
};