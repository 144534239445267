import React from 'react';
import { mockConnectors } from '../mockData';

import { useNavigate } from 'react-router-dom';

export const Sources: React.FC = () => {
  const navigate = useNavigate();
  const sources = mockConnectors.filter(c => c.type === 'source');

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Sources</h1>
        <button 
          onClick={() => navigate('/app/sources/new')}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          New Source
        </button>
      </div>
      
      <div className="bg-white shadow rounded-lg">
        <div className="divide-y">
          {sources.map(source => (
            <div key={source.id} className="p-4 flex justify-between items-center">
              <div>
                <div className="font-medium">{source.name}</div>
                <div className="text-sm text-gray-500">Last sync: {source.lastSync}</div>
              </div>
              <div>
                <span className={`px-2 py-1 rounded-full text-sm ${
                  source.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {source.status}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};