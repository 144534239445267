import React from 'react';

export const TransformDiagram: React.FC = () => {
  return (
    <div className="relative w-full h-[200px] bg-gray-900 text-white rounded-lg shadow-lg p-6 flex flex-col items-center">
      <h3 className="text-lg font-bold mb-4">Transform</h3>
      
      {/* Rappresentazione astratta */}
      <div className="flex items-center justify-center space-x-6">
        {/* Dato grezzo */}
        <div className="w-16 h-16 bg-gray-700 rounded-lg shadow-md flex items-center justify-center">
          <span className="text-xs text-gray-300 font-medium">Raw</span>
        </div>

        {/* Processo di trasformazione */}
        <div className="flex items-center space-x-1">
          {[...Array(5)].map((_, i) => (
            <div
              key={i}
              className={`w-4 h-4 rounded-full ${
                i < 2
                  ? "bg-gray-700"
                  : i === 2
                  ? "bg-blue-500"
                  : "bg-blue-400"
              }`}
            ></div>
          ))}
        </div>

        {/* Dato pulito */}
        <div className="w-16 h-16 bg-blue-600 rounded-full shadow-md flex items-center justify-center">
          <span className="text-xs text-white font-medium">Clean</span>
        </div>
      </div>
    </div>
  );
};
