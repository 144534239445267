// src/components/Header.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp, Menu, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { DemoButton } from './DemoButton';


export const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState<string | null>(null);
    const navigate = useNavigate();
  
    const navigation = [
      {
        title: 'Solution',
        items: [
          { name: 'Data Integration', href: '/data_integration' },
          { name: 'Real-time Sync', href: '#real-time-sync' },
          { name: 'Connectors', href: '#custom-connectors' },
        ],
      },
      {
        title: 'About Us',
        items: [
          { name: 'Our Story', href: '/about' },
          { name: 'Careers', href: '/careers' },
        ],
      },
      {
        title: 'Pricing',
        items: [
          { name: 'Plans', href: '/pricing' },
          { name: 'Enterprise', href: '/pricing' },
        ],
      },
    ];

    const handleNavigate = (href: string) => {
      if (href.startsWith('#')) {
        // Per link interni (anchor)
        const element = document.querySelector(href);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      } else {
        // Per route di React Router
        navigate(href);
      }
      setOpenMenu(null); // Chiude il menu dopo la navigazione
    };
    
  return (
    <header className="fixed w-full top-0 bg-gray-900/80 backdrop-blur-lg z-50">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          {/* Logo - ridimensionato per mobile */}
          <Link to="/" className="flex-shrink-0">
            <img 
              src="/images/logo/company-logo.png"
              alt="DataBridge Logo"
              className="h-6 w-auto sm:h-8 md:h-12" // Dimensioni progressive per i vari breakpoint
            />
          </Link>

          {/* Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navigation.map((item) => (
              <div 
                key={item.title}
                className="relative"
                onMouseEnter={() => setOpenMenu(item.title)}
                onMouseLeave={() => setOpenMenu(null)}
              >
                <button className="flex items-center space-x-1 text-white hover:text-gray-300 transition-colors">
                  <span>{item.title}</span>
                  {openMenu === item.title ? (
                    <ChevronUp className="w-4 h-4" />
                  ) : (
                    <ChevronDown className="w-4 h-4" />
                  )}
                </button>

                <AnimatePresence>
                  {openMenu === item.title && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                      className="absolute left-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-lg py-2"
                    >
                      {item.items.map((subItem) => (
                        <button
                        key={subItem.name}
                        onClick={() => handleNavigate(subItem.href)}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                      >
                        {subItem.name}
                      </button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}

            {/* Try Demo Button */}
            <DemoButton 
              className="px-6 py-2 bg-white text-gray-900 rounded-full font-medium hover:bg-white/90 transition-colors"
            >
              Try Demo
            </DemoButton>
          </div>

          {/* Menu Mobile Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-gray-800"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-white" />
            ) : (
              <Menu className="h-6 w-6 text-white" />
            )}
          </button>

        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <div key={item.title} className="py-2">
                  <button 
                    className="flex items-center w-full px-3 py-2 rounded-md text-white hover:bg-gray-800"
                  >
                    {item.title}
                  </button>
                  <div className="pl-4 mt-2 space-y-2">
                  {item.items.map((subItem) => (
                    <button
                      key={subItem.name}
                      onClick={() => {
                        handleNavigate(subItem.href);
                        setIsMenuOpen(false); // Chiude il menu mobile dopo la navigazione
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                      {subItem.name}
                    </button>
                  ))}
                  </div>
                </div>
              ))}
              <DemoButton 
                className="px-6 py-2 bg-white text-gray-900 rounded-full font-medium hover:bg-white/90 transition-colors"
              >
                Try Demo
              </DemoButton>
            </div>
          </div>
        )}

      </nav>
    </header>
  );
};