// src/components/HeroSection.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { ParticleNetwork } from './ParticleNetwork';
import { DemoButton } from './DemoButton';

export const HeroSection: React.FC = () => {
  return (
    <section className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gray-900">
      {/* Particle Background */}
      <ParticleNetwork />

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-900/50 to-gray-900" />

      {/* Content */}
      <div className="relative z-10 text-center space-y-8 max-w-4xl mx-auto px-4">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: [0.22, 1, 0.36, 1] }}
          className="text-5xl sm:text-6xl lg:text-7xl font-bold text-white"
        >
          Unify Your
          <br />
          <span className="bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
            Ecommerce Data
          </span>
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          className="text-xl text-gray-300 max-w-2xl mx-auto"
        >
          Connect Shopify, Amazon, Facebook Ads, Google Ads and all your sales channels in one place. 
          Get real-time insights across your entire ecommerce operation.
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8 }}
          className="flex justify-center space-x-4"
        >
          <DemoButton
              className="px-8 py-4 bg-white text-gray-900 rounded-full font-medium hover:bg-white/90 transition-all transform hover:scale-105"
            >
              Try Demo
            </DemoButton>
          <a
              href="#features"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('features')?.scrollIntoView({ behavior: 'smooth' });
              }}
              className="px-8 py-4 border border-white/20 rounded-full font-medium hover:bg-white/10 transition-all"
            >
              Learn More
            </a>
        </motion.div>
      </div>
    </section>
  );
};