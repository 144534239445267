// src/components/EmailSignupSection.tsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, CheckCircle, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Subscriber {
  id?: string;
  email: string;
  subscribed_at?: string;
  status: 'active' | 'pending' | 'unsubscribed';
  source: string;
}

export const EmailSignupSection: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const newSubscriber: Subscriber = {
      email,
      status: 'active',
      source: 'landing_page',
    };

    try {
      const { error: supabaseError } = await supabase
        .from('subscribers')
        .insert([newSubscriber]);

      if (supabaseError) {
        if (supabaseError.code === '23505') {
          const { data: existingSubscriber } = await supabase
            .from('subscribers')
            .select('status')
            .eq('email', email)
            .single();

          if (existingSubscriber?.status === 'unsubscribed') {
            const { error: updateError } = await supabase
              .from('subscribers')
              .update({ status: 'active', subscribed_at: new Date().toISOString() })
              .eq('email', email);

            if (!updateError) {
              setIsSubmitted(true);
              return;
            }
          }
          
          setError('This email is already subscribed!');
        } else {
          setError('Something went wrong. Please try again.');
          console.error('Supabase error:', supabaseError);
        }
        return;
      }

      setIsSubmitted(true);
    } catch (err) {
      setError('Unable to subscribe. Please try again later.');
      console.error('Subscription error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.section 
      className="py-20 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-3xl" />
        
        <div className="relative bg-gray-800/50 rounded-3xl border border-white/10 p-8 md:p-12 backdrop-blur-sm">
          <div className="max-w-3xl mx-auto text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Transform Your Ecommerce Operations
              </h2>
              <p className="text-gray-300 mb-8">
                Join ecommerce brands using databridge to streamline their multi-channel operations.
              </p>
            </motion.div>

            {!isSubmitted ? (
              <motion.form 
                onSubmit={handleSubmit}
                className="flex flex-col sm:flex-row gap-4 max-w-xl mx-auto"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
              >
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="flex-1 px-6 py-4 bg-gray-900/50 rounded-full border border-white/10 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors"
                />
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`
                    px-8 py-4 bg-white text-gray-900 rounded-full font-medium
                    flex items-center justify-center
                    hover:bg-white/90 transition-all transform hover:scale-105
                    ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}
                  `}
                >
                  {isLoading ? (
                    <div className="w-6 h-6 border-2 border-gray-900 border-t-transparent rounded-full animate-spin" />
                  ) : (
                    <>
                      Get Started
                      <ArrowRight className="ml-2 w-5 h-5" />
                    </>
                  )}
                </button>
              </motion.form>
            ) : (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                className="flex flex-col items-center text-center space-y-4"
              >
                <CheckCircle className="w-16 h-16 text-green-500" />
                <h3 className="text-xl font-semibold text-white">Thank you for subscribing!</h3>
                <p className="text-gray-300">
                  We'll keep you posted with the latest updates.
                </p>
              </motion.div>
            )}

            {error && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-4 flex items-center justify-center text-red-400 space-x-2"
              >
                <AlertCircle className="w-5 h-5" />
                <span>{error}</span>
              </motion.div>
            )}

            {!isSubmitted && !error && (
              <motion.p 
                className="mt-4 text-sm text-gray-400"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.4 }}
              >
                By subscribing, you agree to receive product updates and marketing communications.
              </motion.p>
            )}
          </div>
        </div>
      </div>
    </motion.section>
  );
};