import { Connector, SyncConnection,PrebuiltConnector,ConnectorConfig,WarehouseConnector,SourceObject } from './types';

export const mockConnectors: Connector[] = [
    {
      id: '1',
      name: 'Salesforce',
      type: 'source',
      status: 'active',
      lastSync: '2 hours ago'
    },
    {
      id: '2',
      name: 'Shopify',
      type: 'source',
      status: 'active',
      lastSync: '1 hour ago'
    },
    {
      id: '3',
      name: 'Snowflake',
      type: 'destination',
      status: 'active'
    }
  ];
  
  export const mockConnections: SyncConnection[] = [
    {
      id: '1',
      sourceName: 'Salesforce',
      destinationName: 'Snowflake',
      status: 'completed',
      frequency: '24 hours',
      lastSync: '2 hours ago'
    },
    {
      id: '2',
      sourceName: 'Shopify',
      destinationName: 'Snowflake',
      status: 'running',
      frequency: '12 hours',
      lastSync: '1 hour ago'
    }
  ];

  export const prebuiltConnectors: PrebuiltConnector[] = [
    {
      id: 'salesforce',
      name: 'Salesforce',
      logo: '/images/sources/salesforce.png',
      description: 'Connect to your Salesforce CRM data',
      category: 'CRM'
    },
    {
      id: 'shopify',
      name: 'Shopify',
      logo: '/images/sources/shopify.png',
      description: 'Import your Shopify store data',
      category: 'E-commerce'
    },
    {
      id: 'zendesk',
      name: 'Zendesk',
      logo: '/images/sources/zendesk.png',
      description: 'Connect to your Zendesk customer service data',
      category: 'CRM'
    },
    {
      id: 'google-drive',
      name: 'Google Drive',
      logo: '/images/sources/gdrive.png',
      description: 'Access your Google Drive files and data',
      category: 'Database'
    },
    {
      id: 'google-ads',
      name: 'Google Ads',
      logo: '/images/sources/gads.png',
      description: 'Import your Google Ads campaign data',
      category: 'Marketing'
    }
  ];

  export const connectorConfigs: Record<string, ConnectorConfig> = {
    shopify: {
      name: 'Shopify',
      logo: '/images/sources/shopify.png',
      category: 'E-commerce',
      description: 'Connect your Shopify store to sync orders, products, and customer data',
      fields: [
        {
          name: 'shop_name',
          label: 'Shop Name',
          type: 'text',
          required: true,
          placeholder: 'your-store.myshopify.com',
          description: 'Your Shopify store URL'
        },
        {
          name: 'api_key',
          label: 'API Key',
          type: 'password',
          required: true,
          description: 'Found in your Shopify admin under Apps > Private apps'
        },
        {
          name: 'api_secret',
          label: 'API Secret',
          type: 'password',
          required: true
        },
        {
          name: 'sync_frequency',
          label: 'Sync Frequency',
          type: 'select',
          required: true,
          options: ['15 minutes', '1 hour', '6 hours', '12 hours', '24 hours']
        }
      ]
    },
    salesforce: {
      name: 'Salesforce',
      logo: '/images/sources/salesforce.png',
      category: 'CRM',
      description: 'Connect to Salesforce to sync leads, contacts, and opportunities',
      fields: [
        {
          name: 'client_id',
          label: 'Client ID',
          type: 'text',
          required: true,
          description: 'Your Salesforce Connected App Client ID'
        },
        {
          name: 'client_secret',
          label: 'Client Secret',
          type: 'password',
          required: true
        },
        {
          name: 'username',
          label: 'Username',
          type: 'text',
          required: true
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          required: true
        },
        {
          name: 'security_token',
          label: 'Security Token',
          type: 'password',
          required: true,
          description: 'Found in your Salesforce settings under Reset Security Token'
        }
      ]
    }
  };

  export const warehouseConnectors: WarehouseConnector[] = [
    {
      id: 'snowflake',
      name: 'Snowflake',
      logo: '/images/destinations/snowflake.png',
      description: 'Connect to Snowflake Data Warehouse for scalable and secure data storage',
      category: 'Data Warehouse',
      documentationUrl: 'https://docs.snowflake.com'
    },
    {
      id: 'bigquery',
      name: 'Google BigQuery',
      logo: '/images/destinations/bigquery.png',
      description: 'Use Google BigQuery for serverless and highly scalable analytics',
      category: 'Data Warehouse',
      documentationUrl: 'https://cloud.google.com/bigquery'
    },
    {
      id: 'redshift',
      name: 'Amazon Redshift',
      logo: '/images/destinations/amazonredshift.png',
      description: 'Connect to Amazon Redshift for fast and managed data warehousing',
      category: 'Data Warehouse',
      documentationUrl: 'https://aws.amazon.com/redshift'
    },
    {
      id: 'azure-synapse',
      name: 'Azure Synapse',
      logo: '/images/destinations/azuresynapse.png',
      description: 'Use Azure Synapse Analytics for enterprise data warehousing',
      category: 'Data Warehouse',
      documentationUrl: 'https://azure.microsoft.com/services/synapse-analytics'
    },
    {
      id: 'databricks',
      name: 'Databricks',
      logo: '/images/destinations/databricks.png',
      description: 'Leverage Databricks for unified analytics platform',
      category: 'Lake',
      documentationUrl: 'https://docs.databricks.com'
    }
  ];
  
  export const warehouseConfigs: Record<string, ConnectorConfig> = {
    snowflake: {
      name: 'Snowflake',
      logo: '/images/destinations/snowflake.png',
      category: 'Data Warehouse',
      description: 'Configure your Snowflake connection to start receiving data',
      fields: [
        {
          name: 'account_name',
          label: 'Account Name',
          type: 'text',
          required: true,
          placeholder: 'org-account',
          description: 'Your Snowflake account identifier (e.g., org-account)'
        },
        {
          name: 'warehouse',
          label: 'Warehouse',
          type: 'text',
          required: true,
          description: 'The name of your Snowflake warehouse'
        },
        {
          name: 'database',
          label: 'Database',
          type: 'text',
          required: true,
          description: 'The name of your Snowflake database'
        },
        {
          name: 'schema',
          label: 'Schema',
          type: 'text',
          required: true,
          placeholder: 'public',
          description: 'The schema where tables will be created'
        },
        {
          name: 'username',
          label: 'Username',
          type: 'text',
          required: true
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          required: true
        },
        {
          name: 'role',
          label: 'Role',
          type: 'text',
          required: false,
          placeholder: 'ACCOUNTADMIN',
          description: 'Optional: Specific role to use'
        }
      ]
    },
    bigquery: {
      name: 'Google BigQuery',
      logo: '/images/destinations/bigquey.png',
      category: 'Data Warehouse',
      description: 'Configure your BigQuery connection to start receiving data',
      fields: [
        {
          name: 'project_id',
          label: 'Project ID',
          type: 'text',
          required: true,
          description: 'Your Google Cloud Project ID'
        },
        {
          name: 'dataset_id',
          label: 'Dataset ID',
          type: 'text',
          required: true,
          description: 'The BigQuery dataset where tables will be created'
        },
        {
          name: 'key_file',
          label: 'Service Account Key',
          type: 'password',
          required: true,
          description: 'Your service account key JSON'
        },
        {
          name: 'location',
          label: 'Dataset Location',
          type: 'select',
          required: true,
          options: ['US', 'EU', 'asia-southeast1', 'us-central1'],
          description: 'The geographic location of the dataset'
        }
      ]
    }
  };

  export const sourceObjects: Record<string, SourceObject[]> = {
    'salesforce': [
      {
        name: 'Account',
        type: 'standard',
        fields: ['Id', 'Name', 'Industry', 'Website', 'Phone', 'BillingAddress'],
        description: 'Standard Salesforce Account object'
      },
      {
        name: 'Contact',
        type: 'standard',
        fields: ['Id', 'FirstName', 'LastName', 'Email', 'Phone', 'AccountId'],
        description: 'Standard Salesforce Contact object'
      },
      {
        name: 'Opportunity',
        type: 'standard',
        fields: ['Id', 'Name', 'Amount', 'StageName', 'CloseDate', 'AccountId'],
        description: 'Standard Salesforce Opportunity object'
      }
    ],
    'shopify': [
      {
        name: 'Orders',
        type: 'standard',
        fields: ['id', 'order_number', 'total_price', 'created_at', 'customer'],
        description: 'Shopify orders data'
      },
      {
        name: 'Products',
        type: 'standard',
        fields: ['id', 'title', 'vendor', 'product_type', 'variants'],
        description: 'Shopify products catalog'
      },
      {
        name: 'Customers',
        type: 'standard',
        fields: ['id', 'email', 'first_name', 'last_name', 'orders_count'],
        description: 'Shopify customers data'
      }
    ]
  };
  
export const timezones = [
  'UTC',
  'America/New_York',
  'America/Los_Angeles',
  'America/Chicago',
  'America/Denver',
  'Europe/London',
  'Europe/Paris',
  'Europe/Rome',
  'Europe/Berlin',
  'Asia/Tokyo',
  'Asia/Shanghai',
  'Asia/Dubai',
  'Australia/Sydney',
  'Pacific/Auckland'
];

