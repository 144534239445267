interface SliderProps {
    min: number;
    max: number;
    value: number;
    onChange: (value: number) => void;
    label?: string;
  }
  
  export const Slider: React.FC<SliderProps> = ({ min, max, value, onChange, label }) => {
    return (
      <div className="space-y-1">
        {label && (
          <label className="text-sm text-gray-500">{label}</label>
        )}
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
          className="w-full"
        />
        <div className="text-sm text-gray-500 text-right">{value}</div>
      </div>
    );
  };