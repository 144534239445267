import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { warehouseConnectors } from '../mockData';

export const NewDestinationPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredConnectors = warehouseConnectors.filter(connector =>
    connector.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Add New Destination</h1>
        <button
          onClick={() => navigate('/app/destinations')}
          className="text-gray-600 hover:text-gray-800"
        >
          ✕
        </button>
      </div>

      <div className="mb-6">
        <input
          type="text"
          placeholder="Search destinations..."
          className="w-full px-4 py-2 border rounded-lg"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredConnectors.map(connector => (
          <button
            key={connector.id}
            className="p-4 border rounded-lg hover:border-blue-500 hover:shadow-lg transition-all text-left"
            onClick={() => navigate(`/app/destinations/new/${connector.id}`)}
          >
            <div className="flex items-start space-x-3">
              <div className="w-10 h-10 flex-shrink-0 flex items-center justify-center bg-gray-50">
                <img 
                  src={connector.logo}
                  alt={connector.name}
                  className="max-w-full max-h-full object-contain"
                  onError={(e) => {
                    console.error('Failed to load image:', connector.logo);
                    const imgElement = e.target as HTMLImageElement;
                    // Opzionale: mostra le iniziali del nome come fallback
                    imgElement.style.display = 'none';
                    imgElement.parentElement!.textContent = connector.name[0];
                  }}
                />
              </div>
              <div>
                <h3 className="font-medium">{connector.name}</h3>
                <p className="text-sm text-gray-500">{connector.description}</p>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};