import React from 'react';
import { motion } from 'framer-motion';

export const SyncDiagram: React.FC = () => {
    const [isRunning, setIsRunning] = React.useState(true);
  
    return (
      <div className="relative w-full h-[300px] p-4">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-white font-medium">Live Sync Status</h4>
          <button
            onClick={() => setIsRunning(!isRunning)}
            className={`px-4 py-2 rounded-lg ${
              isRunning ? 'bg-green-500' : 'bg-gray-500'
            } text-white text-sm`}
          >
            {isRunning ? 'Running' : 'Paused'}
          </button>
        </div>
  
        <div className="grid grid-cols-2 gap-8">
          <div className="space-y-4">
            <div className="bg-gray-800 p-4 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-400">Source</span>
                <motion.div
                  animate={isRunning ? {
                    scale: [1, 1.2, 1],
                    opacity: [1, 0.7, 1]
                  } : {}}
                  transition={{ repeat: Infinity, duration: 1.5 }}
                  className="h-2 w-2 rounded-full bg-green-500"
                />
              </div>
              <div className="text-xs text-gray-500 space-y-1">
                <div className="flex justify-between">
                  <span>Records Processed</span>
                  <motion.span
                    animate={isRunning ? { opacity: [1, 0.5, 1] } : {}}
                    transition={{ repeat: Infinity, duration: 1 }}
                  >
                    1,234
                  </motion.span>
                </div>
                <div className="flex justify-between">
                  <span>Last Sync</span>
                  <span>2 min ago</span>
                </div>
              </div>
            </div>
  
            <motion.div
              className="h-1 bg-blue-500/20 rounded-full overflow-hidden"
              animate={isRunning ? { opacity: [0.5, 1] } : {}}
              transition={{ repeat: Infinity, duration: 1 }}
            >
              <motion.div
                className="h-full bg-blue-500"
                animate={isRunning ? { 
                  x: ['-100%', '100%'],
                } : {}}
                transition={{ 
                  repeat: Infinity, 
                  duration: 2,
                  ease: "linear"
                }}
              />
            </motion.div>
          </div>
  
          <div className="space-y-4">
            <div className="bg-gray-800 p-4 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-400">Destination</span>
                <motion.div
                  animate={isRunning ? {
                    scale: [1, 1.2, 1],
                    opacity: [1, 0.7, 1]
                  } : {}}
                  transition={{ 
                    repeat: Infinity,
                    duration: 1.5,
                    delay: 0.5
                  }}
                  className="h-2 w-2 rounded-full bg-green-500"
                />
              </div>
              <div className="text-xs text-gray-500 space-y-1">
                <div className="flex justify-between">
                  <span>Records Written</span>
                  <motion.span
                    animate={isRunning ? { opacity: [1, 0.5, 1] } : {}}
                    transition={{ repeat: Infinity, duration: 1 }}
                  >
                    1,234
                  </motion.span>
                </div>
                <div className="flex justify-between">
                  <span>Success Rate</span>
                  <span>100%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  