import React from 'react';
import { motion } from 'framer-motion';
import { 
  Network, // for scattered data
  PackageSearch, // for inventory
  Target, // for marketing
  Database,
  ChartBar,
  Plug2
} from 'lucide-react';


const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] },
};

const hoverEffect = {
  whileHover: { scale: 1.05, transition: { duration: 0.3 } },
};

export const ProblemSolutionSection: React.FC = () => {
  return (
    <motion.section className="py-20 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto" initial="initial" animate="animate" viewport={{ once: true }}>
      {/* Problem Section */}
      <motion.div className="text-center mb-16" variants={fadeInUp}>
        <h2 className="text-4xl font-bold text-white mb-6">The Problem</h2>
        <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
          <motion.div className="bg-gray-800/50 p-6 rounded-xl border border-gray-700 text-center max-w-xs shadow-lg min-h-[230px]" {...hoverEffect}>
            <Network className="w-12 h-12 text-red-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white">Scattered Sales Data</h3>
            <p className="text-gray-300 mt-2">
              Sales data trapped in multiple platforms making it impossible to see the full picture.
            </p>
          </motion.div>
          <motion.div className="bg-gray-800/50 p-6 rounded-xl border border-gray-700 text-center max-w-xs shadow-lg min-h-[230px]" {...hoverEffect}>
            <PackageSearch className="w-12 h-12 text-yellow-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white">Inventory Chaos</h3>
            <p className="text-gray-300 mt-2">
              Manual stock reconciliation across channels leading to oversells and stockouts.
            </p>
          </motion.div>
          <motion.div className="bg-gray-800/50 p-6 rounded-xl border border-gray-700 text-center max-w-xs shadow-lg min-h-[230px]" {...hoverEffect}>
            <Target className="w-12 h-12 text-blue-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white">Marketing Blindspots</h3>
            <p className="text-gray-300 mt-2">
              Unable to track campaign performance across different marketplaces.
            </p>
          </motion.div>
        </div>
      </motion.div>

      {/* Solution Section */}
      <motion.div className="text-center" variants={fadeInUp} transition={{ delay: 0.2 }}>
        <h2 className="text-4xl font-bold text-white mb-6">Our Solution</h2>
        <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
          <motion.div className="bg-gradient-to-r from-blue-600/30 to-purple-600/30 p-6 rounded-xl border border-gray-700 text-center max-w-xs shadow-lg" {...hoverEffect}>
            <Database className="w-12 h-12 text-green-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white">Unified Data</h3>
            <p className="text-gray-300 mt-2">
              All your sales channels in one place, from Shopify to marketplaces.
            </p>
          </motion.div>
          <motion.div className="bg-gradient-to-r from-purple-600/30 to-pink-600/30 p-6 rounded-xl border border-gray-700 text-center max-w-xs shadow-lg" {...hoverEffect}>
            <ChartBar className="w-12 h-12 text-pink-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white">Cross-channel Analytics</h3>
            <p className="text-gray-300 mt-2">
              Track performance, customer behavior, and ROI across all platforms.
            </p>
          </motion.div>
          <motion.div className="bg-gradient-to-r from-yellow-600/30 to-green-600/30 p-6 rounded-xl border border-gray-700 text-center max-w-xs shadow-lg" {...hoverEffect}>
            <Plug2 className="w-12 h-12 text-yellow-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white">Seamless Integrations</h3>
            <p className="text-gray-300 mt-2">
              Connect with your favorite tools effortlessly.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </motion.section>
  );
};