// src/components/Sidebar.tsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Database, 
  UploadCloud, 
  ConeIcon,
  Wand2
} from 'lucide-react';

export const Sidebar: React.FC = () => {
  const location = useLocation();

  const isActiveRoute = (path: string) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div className="w-64 bg-gray-900 text-white min-h-screen flex flex-col">
      {/* Logo Section */}
      <div className="p-6 border-b border-white/10">
        <Link to="/" className="flex items-center justify-right">
          <div className="flex-shrink-0">
            <img 
              src="/images/logo/company-logo.png"
              alt="DataBridge Logo"
              className="h-16 w-auto object-contain"
              onError={(e) => {
                console.error('Failed to load logo');
                const imgElement = e.target as HTMLImageElement;
                imgElement.style.display = 'none';
              }}
            />
          </div>
        </Link>
      </div>

      {/* Navigation con icone Lucide */}
      <nav className="flex-1 px-4 py-6 space-y-1">
        <Link 
          to="/app/sources" 
          className={`flex items-center px-4 py-3 rounded-lg transition-all ${
            isActiveRoute('/sources')
              ? 'bg-gray-800 text-white'
              : 'text-gray-300 hover:bg-gray-800/50'
          }`}
        >
          <Database className="w-5 h-5 mr-3" /> {/* Icona Database per Sources */}
          <span className="font-medium">Sources</span>
        </Link>
        
        <Link 
          to="/app/destinations" 
          className={`flex items-center px-4 py-3 rounded-lg transition-all ${
            isActiveRoute('/destinations')
              ? 'bg-gray-800 text-white'
              : 'text-gray-300 hover:bg-gray-800/50'
          }`}
        >
          <UploadCloud className="w-5 h-5 mr-3" /> {/* Icona UploadCloud per Destinations */}
          <span className="font-medium">Destinations</span>
        </Link>
        
        <Link 
          to="/app/connections" 
          className={`flex items-center px-4 py-3 rounded-lg transition-all ${
            isActiveRoute('/connections')
              ? 'bg-gray-800 text-white'
              : 'text-gray-300 hover:bg-gray-800/50'
          }`}
        >
          <ConeIcon className="w-5 h-5 mr-3" /> {/* Icona Connection per Connections */}
          <span className="font-medium">Connections</span>
        </Link>

        <Link  
          to="/app/transformations" 
          className={`flex items-center px-4 py-3 rounded-lg transition-all ${
            isActiveRoute('/transformations')
              ? 'bg-gray-800 text-white'
              : 'text-gray-300 hover:bg-gray-800/50'
          }`}
        >
          <Wand2 className="w-5 h-5 mr-3" />
          <span className="font-medium">Transformations</span>
        </Link>

      </nav>

      {/* Footer */}
      <div className="p-4 border-t border-white/10">
        <div className="text-xs text-gray-400 text-center">
          © 2024 DataBridge. All rights reserved.
        </div>
      </div>
    </div>
  );
};