import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Rocket, LineChart, Building2, Network } from 'lucide-react';
import { Header } from './Header';

export const AboutUsPage: React.FC = () => {
  useEffect(() => {
    // Scrolla sempre in alto quando la pagina viene caricata
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gray-900 min-h-screen text-white">
          <Header/>
      <main className="w-full mx-auto p-6 pt-12"></main>
      <div className="min-h-screen bg-gray-900">
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center"
            >
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Empowering Data-Driven Decisions
              </h1>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                We're building the future of data infrastructure, enabling businesses to move faster and make better decisions.
              </p>
            </motion.div>
          </div>
        </div>

        {/* Mission Section */}
        <section className="py-20 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="space-y-6"
              >
                <h2 className="text-3xl font-bold text-white">Our Mission</h2>
                <p className="text-gray-300 text-lg leading-relaxed">
                  In today's fast-paced business environment, the gap between data collection and decision-making is often too wide. We're here to bridge that gap.
                </p>
                <p className="text-gray-300 text-lg leading-relaxed">
                  databridge accelerates your journey from raw data to actionable insights. By providing a robust and intuitive data infrastructure, we empower organizations to make informed decisions faster than ever before.
                </p>
              </motion.div>
              <div className="grid grid-cols-2 gap-6">
                {[
                  { icon: Rocket, title: 'Speed', text: 'Accelerate your data-driven decisions' },
                  { icon: LineChart, title: 'Growth', text: 'Scale your data infrastructure effortlessly' },
                  { icon: Building2, title: 'Foundation', text: 'Build on a reliable data platform' },
                  { icon: Network, title: 'Connection', text: 'Unify your data ecosystem' }
                ].map(({ icon: Icon, title, text }) => (
                  <motion.div
                    key={title}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="p-6 bg-gray-800/50 rounded-xl border border-gray-700"
                  >
                    <Icon className="w-8 h-8 text-blue-400 mb-4" />
                    <h3 className="text-white font-medium mb-2">{title}</h3>
                    <p className="text-gray-400 text-sm">{text}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Vision Section */}
        <section className="py-20 bg-gray-800/30">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center max-w-3xl mx-auto"
            >
              <h2 className="text-3xl font-bold text-white mb-6">Our Vision</h2>
              <p className="text-gray-300 text-lg leading-relaxed">
                We envision a world where every business, regardless of size, has access to enterprise-grade data infrastructure. A world where technical complexity never stands in the way of data-driven innovation.
              </p>
            </motion.div>
          </div>
        </section>
      </div>
    </div>

  );
};