// src/components/Destinations.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { mockConnectors } from '../mockData';

export const Destinations: React.FC = () => {
  const navigate = useNavigate();
  const destinations = mockConnectors.filter(c => c.type === 'destination');

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Destinations</h1>
        <button
          onClick={() => navigate('/app/destinations/new')}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          New Destination
        </button>
      </div>
      
      <div className="bg-white shadow rounded-lg">
        <div className="divide-y">
          {destinations.map(destination => (
            <div key={destination.id} className="p-4 flex justify-between items-center">
              <div>
                <div className="font-medium">{destination.name}</div>
                <div className="text-sm text-gray-500">Last sync: {destination.lastSync}</div>
              </div>
              <div>
                <span className={`px-2 py-1 rounded-full text-sm ${
                  destination.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {destination.status}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};