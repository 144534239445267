// src/components/Connections.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { mockConnections } from '../mockData';

export const Connections: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Connections</h1>
        <button 
          onClick={() => navigate('/app/connections/new')}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          New Connection
        </button>
      </div>
      
      <div className="bg-white shadow rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Source
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Destination
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Frequency
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Last Sync
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {mockConnections.map(connection => (
              <tr key={connection.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {connection.sourceName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {connection.destinationName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 rounded-full text-sm ${
                    connection.status === 'completed' ? 'bg-green-100 text-green-800' :
                    connection.status === 'running' ? 'bg-blue-100 text-blue-800' :
                    'bg-red-100 text-red-800'
                  }`}>
                    {connection.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {connection.frequency}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {connection.lastSync}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};