import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mockConnectors, sourceObjects, timezones } from '../mockData';
import { Connector } from '../types';

export const NewConnectionPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedSource, setSelectedSource] = useState<Connector | null>(null);
  const [selectedDestination, setSelectedDestination] = useState<Connector | null>(null);
  const [selectedObjects, setSelectedObjects] = useState<{
    [key: string]: {
      selected: boolean;
      schema: string;
      table: string;
      fields: string[];
    };
  }>({});
  const [schedule, setSchedule] = useState({
    frequency: 'hours',
    interval: 24,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    startTime: ''
  });

  const sources = mockConnectors.filter(c => c.type === 'source');
  const destinations = mockConnectors.filter(c => c.type === 'destination');
  
  const handleObjectSelect = (objectName: string) => {
    setSelectedObjects(prev => ({
      ...prev,
      [objectName]: {
        selected: !prev[objectName]?.selected,
        schema: prev[objectName]?.schema || '',
        table: prev[objectName]?.table || objectName.toLowerCase(),
        fields: prev[objectName]?.fields || []
      }
    }));
  };

  const handleSave = async () => {
    try {
      // Qui andrà la logica per salvare la configurazione
      const config = {
        sourceId: selectedSource?.id,
        destinationId: selectedDestination?.id,
        objects: selectedObjects,
        schedule
      };
      console.log('Saving connection:', config);
      navigate('/app/connections');
    } catch (error) {
      console.error('Failed to save connection:', error);
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Create New Connection</h1>
        <button
          onClick={() => navigate('/app/connections')}
          className="text-gray-600 hover:text-gray-800"
        >
          ✕
        </button>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Source Selection Column */}
        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow p-4">
            <h2 className="text-lg font-medium mb-4">Select Source</h2>
            <div className="space-y-2">
              {sources.map(source => (
                <button
                  key={source.id}
                  onClick={() => setSelectedSource(source)}
                  className={`w-full p-3 rounded border text-left ${
                    selectedSource?.id === source.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:border-blue-200'
                  }`}
                >
                  <div className="font-medium">{source.name}</div>
                  <div className="text-sm text-gray-500">
                    {source.type}
                  </div>
                </button>
              ))}
            </div>
          </div>

          {selectedSource && sourceObjects[selectedSource.name.toLowerCase()] && (
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="text-lg font-medium mb-4">Select Objects</h2>
              <div className="space-y-3">
                {sourceObjects[selectedSource.name.toLowerCase()].map(obj => (
                  <div key={obj.name} className="space-y-2">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id={obj.name}
                        checked={selectedObjects[obj.name]?.selected || false}
                        onChange={() => handleObjectSelect(obj.name)}
                        className="h-4 w-4 text-blue-600 rounded border-gray-300"
                      />
                      <label htmlFor={obj.name} className="ml-2 block text-sm">
                        <span className="font-medium">{obj.name}</span>
                        <span className="text-gray-500 text-xs ml-2">
                          ({obj.fields.length} fields)
                        </span>
                      </label>
                    </div>
                    {selectedObjects[obj.name]?.selected && (
                      <div className="ml-6 space-y-2">
                        <div>
                          <label className="block text-xs text-gray-500">
                            Available Fields
                          </label>
                          <div className="mt-1 text-sm">
                            {obj.fields.join(', ')}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Destination Selection Column */}
        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow p-4">
            <h2 className="text-lg font-medium mb-4">Select Destination</h2>
            <div className="space-y-2">
              {destinations.map(destination => (
                <button
                  key={destination.id}
                  onClick={() => setSelectedDestination(destination)}
                  className={`w-full p-3 rounded border text-left ${
                    selectedDestination?.id === destination.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:border-blue-200'
                  }`}
                >
                  <div className="font-medium">{destination.name}</div>
                  <div className="text-sm text-gray-500">
                    {destination.type}
                  </div>
                </button>
              ))}
            </div>
          </div>

          {selectedDestination && Object.keys(selectedObjects).length > 0 && (
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="text-lg font-medium mb-4">Configure Destination</h2>
              <div className="space-y-4">
                {Object.entries(selectedObjects)
                  .filter(([_, config]) => config.selected)
                  .map(([objectName, config]) => (
                    <div key={objectName} className="space-y-2">
                      <div className="font-medium">{objectName}</div>
                      <div className="grid grid-cols-2 gap-2">
                        <div>
                          <label className="block text-sm text-gray-500">
                            Schema
                          </label>
                          <input
                            type="text"
                            value={config.schema}
                            onChange={(e) => setSelectedObjects(prev => ({
                              ...prev,
                              [objectName]: {
                                ...prev[objectName],
                                schema: e.target.value
                              }
                            }))}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm"
                            placeholder="e.g., public"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-500">
                            Table Name
                          </label>
                          <input
                            type="text"
                            value={config.table}
                            onChange={(e) => setSelectedObjects(prev => ({
                              ...prev,
                              [objectName]: {
                                ...prev[objectName],
                                table: e.target.value
                              }
                            }))}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm"
                            placeholder="e.g., customers"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Scheduling Section */}
      {selectedSource && selectedDestination && Object.keys(selectedObjects).some(key => selectedObjects[key].selected) && (
        <div className="mt-6 bg-white rounded-lg shadow p-4">
          <h2 className="text-lg font-medium mb-4">Schedule Sync</h2>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm text-gray-500">Frequency</label>
              <select
                value={schedule.frequency}
                onChange={(e) => setSchedule(prev => ({
                  ...prev,
                  frequency: e.target.value as any
                }))}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              >
                <option value="minutes">Minutes</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>
              </select>
            </div>
            <div>
              <label className="block text-sm text-gray-500">Interval</label>
              <input
                type="number"
                value={schedule.interval}
                onChange={(e) => setSchedule(prev => ({
                  ...prev,
                  interval: parseInt(e.target.value)
                }))}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                min="1"
              />
            </div>
            <div>
                <label className="block text-sm text-gray-500">Timezone</label>
                <select
                    value={schedule.timezone}
                    onChange={(e) => setSchedule(prev => ({
                    ...prev,
                    timezone: e.target.value
                    }))}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                >
                    {timezones.map(tz => (
                    <option key={tz} value={tz}>{tz}</option>
                    ))}
                </select>
            </div>
          </div>
        </div>
      )}

      {/* Action Buttons */}
      <div className="mt-6 flex justify-end space-x-3">
        <button
          onClick={() => navigate('/app/connections')}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          disabled={!selectedSource || !selectedDestination || !Object.keys(selectedObjects).some(key => selectedObjects[key].selected)}
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Create Connection
        </button>
      </div>
    </div>
  );
};