// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LandingPage } from './components/LandingPage';
import { Sidebar } from './components/Sidebar';
import { Sources } from './components/Sources';
import { Destinations } from './components/Destinations';
import { Connections } from './components/Connections';
import { NewSourcePage } from './components/NewSourcePage';
import { NewDestinationPage } from './components/NewDestinationPage';
import { ConnectorConfigPage } from './components/ConnectorConfigPage';
import { WarehouseConfigPage } from './components/WarehouseConfigPage';
import { NewConnectionPage } from './components/NewConnectionPage';
import { TransformationsPage } from './components/TransformationSection';
import { AboutUsPage } from './components/AboutUsPage';
import { CareersPage } from './components/CareersPage';
import { DataIntegrationPage } from './components/DataIntegrationPage';
import {ContactUsPage} from './components/ContactUsPage';
import PricingPage from './components/PricingPage';



export const App: React.FC = () => {
  return (   
    <div className="relative"> 
      <Router>
        <Routes>
          {/* Landing Page Route */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/data_integration" element={<DataIntegrationPage />} />
          <Route path="/contact" element={<ContactUsPage/>} />
          <Route path="/pricing" element={<PricingPage/>} />

          {/* App Routes con Sidebar */}
          <Route
            path="/app/*"
            element={
              <div className="flex h-screen bg-gray-100">
                <Sidebar />
                <div className="flex-1 overflow-auto">
                  <Routes>
                    <Route path="/sources" element={<Sources />} />
                    <Route path="/sources/new" element={<NewSourcePage />} />
                    <Route path="/sources/new/:connectorId" element={<ConnectorConfigPage />} />
                    <Route path="/destinations" element={<Destinations />} />
                    <Route path="/destinations/new" element={<NewDestinationPage />} />
                    <Route path="/destinations/new/:warehouseId" element={<WarehouseConfigPage />} />
                    <Route path="/connections" element={<Connections />} />
                    <Route path="/connections/new" element={<NewConnectionPage />} />
                    <Route path="/transformations" element={<TransformationsPage />} />
                    <Route path="/" element={<Navigate to="/app/sources" replace />} />
                  </Routes>
                </div>
              </div>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;