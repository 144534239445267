import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { ShoppingCart, Store, CircleDollarSign, ShoppingBag, Database } from 'lucide-react';

const sourceConnectors = [
  { Icon: ShoppingCart, label: 'Shopify' },
  { Icon: Store, label: 'WooCommerce' },
  { Icon: CircleDollarSign, label: 'Stripe' },
  { Icon: ShoppingBag, label: 'Amazon' }
] as const;

const AnimatedParticles = ({ count = 6, startPosition = "20%", endPosition = "50%", color = "bg-purple-400" }) => (
  <>
    {[...Array(count)].map((_, i) => (
      <motion.div
        key={`particle-${i}`}
        className={`absolute h-2 w-2 rounded-full ${color}`}
        initial={{ 
          left: startPosition, 
          top: startPosition === "20%" ? `${(i * 15) + 10}%` : "50%",
          opacity: 0,
          scale: startPosition === "20%" ? 1 : 0.5
        }}
        animate={{ 
          left: endPosition,
          top: "50%",
          opacity: [0, 1, 0],
          scale: startPosition === "20%" ? 0.5 : 1
        }}
        transition={{
          duration: 3,
          delay: startPosition === "20%" ? i * 0.4 : 3 + (i * 0.4),
          repeat: Infinity,
          ease: "easeOut"
        }}
      />
    ))}
  </>
);

export const DataFlowSection: React.FC = () => {
  const renderSources = useMemo(() => (
    sourceConnectors.map((connector, index) => (
      <motion.div
        key={connector.label}
        className="flex items-center justify-end gap-4"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 0.7, x: 0 }}
        transition={{ delay: index * 0.2 }}
      >
        <span className="text-white/50 text-sm hidden md:block">
          {connector.label}
        </span>
        <div className="w-12 h-12 bg-gray-800/50 rounded-lg flex items-center justify-center border border-white/10">
          <connector.Icon className="w-6 h-6 text-white/70" />
        </div>
      </motion.div>
    ))
  ), []);

  return (
    <section className="py-8 relative overflow-hidden bg-gray-900/50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-center min-h-[250px]">
          <div className="flex flex-col gap-8 absolute left-0 md:left-20">
            {renderSources}
          </div>

          <motion.div
            className="w-40 h-40 md:w-56 md:h-56 relative z-10"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="absolute inset-0 bg-blue-500/10 rounded-lg blur-2xl" />
            <div className="relative w-full h-full bg-gray-800/50 rounded-lg border border-white/10 flex items-center justify-center">
              <img 
                src="/images/logo/company-logo.png"
                alt="DataBridge Logo"
                className="w-32 h-32 md:w-40 md:h-40 object-contain opacity-80"
                loading="lazy"
              />
            </div>
          </motion.div>

          <div className="absolute right-0 md:right-20 flex items-center gap-4">
            <div className="w-12 h-12 bg-gray-800/50 rounded-lg flex items-center justify-center border border-white/10">
              <Database className="w-6 h-6 text-white/70" />
            </div>
            <span className="text-white/50 text-sm hidden md:block">
              Data Warehouse
            </span>
          </div>

          <div className="absolute inset-0 pointer-events-none">
            <AnimatedParticles count={6} startPosition="20%" endPosition="50%" color="bg-purple-400" />
            <AnimatedParticles count={6} startPosition="50%" endPosition="80%" color="bg-pink-400" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataFlowSection;