import React from 'react';
import { motion } from 'framer-motion';
import { Header } from './Header';
import { 
  HeartCrack,
  Construction,
  Wrench,
  Timer,
  Coffee,
  Smile
} from 'lucide-react';
import { DemoButton } from './DemoButton';

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
};

export const PricingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      
      {/* Main Content */}
      <motion.main 
        className="py-20 px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto text-center"
        variants={fadeInUp}
      >
        {/* Fun Header Section */}
        <motion.div 
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="mb-12"
        >
          <HeartCrack className="w-16 h-16 text-pink-400 mx-auto mb-6" />
          <h1 className="text-4xl font-bold mb-4">Oops! You Caught Us!</h1>
          <p className="text-xl text-gray-400 mb-8">
            We're still building our payment system with love and lots of coffee ☕
          </p>
        </motion.div>

        {/* Fun Status Update */}
        <motion.div 
          className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-8 rounded-2xl border border-white/10 mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <h2 className="text-2xl font-semibold mb-6">Current Status:</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-left">
            <div className="flex items-start space-x-4">
              <Construction className="w-6 h-6 text-yellow-400 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-medium">Under Construction</h3>
                <p className="text-gray-400">Our dev team is coding like there's no tomorrow</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Coffee className="w-6 h-6 text-brown-400 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-medium">Coffee Counter</h3>
                <p className="text-gray-400">1,392 cups and counting...</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Timer className="w-6 h-6 text-green-400 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-medium">Estimated Time</h3>
                <p className="text-gray-400">Coming really soon™</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Wrench className="w-6 h-6 text-blue-400 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-medium">Current Task</h3>
                <p className="text-gray-400">Making everything awesome for you</p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Call to Action */}
        <motion.div 
          className="max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <div className="flex items-center justify-center mb-8">
            <Smile className="w-8 h-8 text-yellow-400 mr-3" />
            <h2 className="text-2xl font-bold">But Don't Go Just Yet!</h2>
          </div>
          <p className="text-gray-300 mb-8">
            While we're putting the finishing touches on our pricing plans, why not try our demo? 
            It's completely free and we'd love to hear your feedback!
          </p>
          <DemoButton
            className="px-8 py-4 bg-white text-gray-900 rounded-full font-medium hover:bg-white/90 transition-all transform hover:scale-105"
          >
            Try Our Demo Instead
          </DemoButton>
        </motion.div>
      </motion.main>

      {/* Footer */}
      <footer className="py-8 px-4 text-center text-gray-400 border-t border-white/10">
        <p className="text-sm">
          © 2024 Databridge. We promise to have proper pricing plans before the sun explodes 🌞
        </p>
      </footer>
    </div>
  );
};

export default PricingPage;