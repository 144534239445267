// src/components/LoadingOverlay.tsx
import React from 'react';

export const LoadingOverlay: React.FC = () => {
  return (
    <div 
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900/95"
      style={{ 
        zIndex: 9999,
        position: 'fixed',
        minHeight: '100vh',
        minWidth: '100vw'
      }}
    >
      <div className="flex flex-col items-center space-y-6">
        <div className="w-20 h-20 border-4 border-gray-700 border-t-white rounded-full animate-spin" />
        <div className="text-white text-xl">Loading...</div>
      </div>
    </div>
  );
};