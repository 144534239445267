// src/components/ParticleNetwork.tsx
import React, { useEffect, useRef } from 'react';

interface Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  size: number;
}

export const ParticleNetwork: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d', { alpha: false })!;  // Ottimizzazione per il context
    let animationFrameId: number;
    let particles: Particle[] = [];


    const setCanvasSize = () => {
      const { width, height } = canvas.getBoundingClientRect();
      canvas.width = width;
      canvas.height = height;
    };

    // Ridotto il numero di particelle per migliorare le performance
    const initParticles = () => {
      particles = Array.from({ length: 200 }, () => ({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        vx: (Math.random() - 0.5) * 0.3,  // Velocità ridotta
        vy: (Math.random() - 0.5) * 0.3,  // Velocità ridotta
        size: Math.random() * 2 + 1
      }));
    };

    const draw = () => {
      ctx.fillStyle = '#111827';  // bg-gray-900
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Rimozione shadowBlur per migliorare le performance
      particles.forEach((particle, i) => {
        particle.x += particle.vx;
        particle.y += particle.vy;

        if (particle.x < 0 || particle.x > canvas.width) particle.vx *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.vy *= -1;

        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fillStyle = 'rgba(66, 153, 225, 0.7)';
        ctx.fill();

        // Ridotto il numero di connessioni controllando solo le particelle vicine
        for (let j = i + 1; j < particles.length; j++) {
          const otherParticle = particles[j];
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = dx * dx + dy * dy;  // Ottimizzazione: non calcoliamo la radice quadrata

          if (distance < 15000) {  // 15000 = 122.5 * 122.5
            ctx.beginPath();
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.strokeStyle = `rgba(66, 153, 225, ${0.2 * (1 - distance / 15000)})`;
            ctx.lineWidth = 0.6;
            ctx.stroke();
          }
        }
      });

      animationFrameId = requestAnimationFrame(draw);
    };

    // Throttle dell'evento mousemove per migliorare le performance
    let lastTime = 0;
    const handleMouseMove = (e: MouseEvent) => {
      const currentTime = Date.now();
      if (currentTime - lastTime < 16) return;  // Limita a circa 60fps
      
      lastTime = currentTime;
    };

    const handleResize = () => {
      setCanvasSize();
      initParticles();
    };

    setCanvasSize();
    initParticles();
    draw();

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="absolute inset-0 w-full h-full"
      style={{ opacity: 0.95 }}
    />
  );
};