// src/components/LandingPage.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { 
  Zap,
  RefreshCw,
  Shield,
  BarChart3,
  Eye,
  Gauge,
  Database,
  Cloud,
  Layers,
  Settings,
  Upload,
  Download,
  GitBranch,
} from 'lucide-react';
import { Header } from './Header';
import { DataFlowSection } from './DataFlowSection';
import { EmailSignupSection } from './EmailSignupSection';
import { DemoButton } from './DemoButton';
import {ProblemSolutionSection} from './ProblemSolutionSection';
import { HeroSection } from './HeroSection';
import { Link } from 'react-router-dom';


// Animazioni personalizzate
const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
};

const fadeInScale = {
  initial: { opacity: 0, scale: 0.9 },
  animate: { opacity: 1, scale: 1 },
  transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
};

export const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header/>
      <HeroSection/>

      <DataFlowSection />

      {/* Features Grid */}
      <motion.section 
        id="features"
        className="py-20 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
      >
        <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            variants={fadeInScale}
        >
            {features.map((feature, index) => (
                <motion.div
                    key={feature.title}
                    className="p-6 bg-gray-800/50 rounded-2xl border border-white/10 hover:border-white/20 transition-all"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                >
                    <div className="mb-4">{feature.icon}</div>
                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                </motion.div>
            ))}
        </motion.div>
      </motion.section>

      {/* Email Signup */}
      <EmailSignupSection/>
      
      <ProblemSolutionSection/>

      {/* Integration Section */}
      <motion.section 
        className="py-20 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
        variants={fadeInUp}
      >
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Connect Everything</h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Pre-built connectors for all your favorite tools and platforms. 
            No coding required.
          </p>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7 gap-4">
            {connectors.map((connector, index) => (
                <motion.div
                key={index}
                className="aspect-square bg-gray-800/50 rounded-lg p-4 flex flex-col items-center justify-center hover:bg-gray-800/70 transition-all"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                >
                {connector.icon}
                <span className="text-xs mt-2 text-gray-400">{connector.name}</span>
                </motion.div>
            ))}
        </div>
      </motion.section>

      {/* CTA Section */}
      <motion.section 
        className="py-20 px-4 sm:px-6 lg:px-8"
        variants={fadeInScale}
      >
        <div className="max-w-4xl mx-auto text-center bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-12 rounded-2xl border border-white/10">
          <h2 className="text-3xl font-bold mb-4">Ready to Get Started?</h2>
          <p className="text-gray-300 mb-8">
            Join all the data teams who trust databridge for their mission-critical data pipelines.
          </p>
          <DemoButton
            className="px-8 py-4 bg-white text-gray-900 rounded-full font-medium hover:bg-white/90 transition-all transform hover:scale-105"
          >
            Try Demo
          </DemoButton>
        </div>
      </motion.section>

      {/* Footer */}
      <footer className="py-12 px-4 sm:px-6 lg:px-8 border-t border-white/10">
        <div className="max-w-7xl mx-auto flex justify-between items-start">
          {/* Logo e descrizione */}
          <div className="flex flex-col items-start max-w-xl">
            <img 
              src="/images/logo/company-logo.png"
              alt="DataBridge Logo"
              className="h-14 w-auto mb-4"
            />
            <p className="text-gray-400 text-sm text-left pl-4">
            The modern data integration platform for ecommerce. We help online retailers consolidate sales, inventory, customer and advertising data across all their sales channels.
            </p>
          </div>

          {/* Collegamenti a destra */}
          <div className="flex flex-col space-y-4">
            <Link to="/contact" className="text-gray-400 hover:text-white text-right text-sm transition-colors duration-200">
              Contact Us
            </Link>
            <Link to="/about" className="text-gray-400 hover:text-white text-right text-sm transition-colors duration-200">
              About Us
            </Link>
          </div>
        </div>
        {/* Sezione copyright */}
        <div className="max-w-7xl mx-auto pt-8">
          <p className="text-gray-400 text-sm text-center">
            © 2024 Databridge. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

// Features data
const features = [
  {
    icon: <Zap className="w-8 h-8 text-blue-400" />,
    title: 'Unified Data',
    description: 'Connect your Shopify, Amazon, and marketplace data in one central view.'
  },
  {
    icon: <RefreshCw className="w-8 h-8 text-purple-400" />,
    title: 'Real-time Sales Sync',
    description: 'Track revenue, inventory, and customer data across all your sales channels instantly.'
  },
  {
    icon: <Shield className="w-8 h-8 text-green-400" />,
    title: 'Secure Integration',
    description: 'Enterprise-grade security for your sensitive customer and transaction data.'
  },
  {
    icon: <BarChart3 className="w-8 h-8 text-yellow-400" />,
    title: 'Sales Analytics',
    description: 'Generate cross-channel insights from your consolidated ecommerce data.'
  },
  {
    icon: <Eye className="w-8 h-8 text-pink-400" />,
    title: 'Inventory Control',
    description: 'Track stock levels across all platforms to prevent stockouts and overstock.'
  },
  {
    icon: <Gauge className="w-8 h-8 text-indigo-400" />,
    title: 'Scale With You',
    description: 'Handle millions of orders and products across multiple marketplaces.'
  }
];

const connectors = [
  { icon: <Database className="w-8 h-8" />, name: 'Shopify' },
  { icon: <Cloud className="w-8 h-8" />, name: 'Amazon' },
  { icon: <Layers className="w-8 h-8" />, name: 'WooCommerce' },
  { icon: <Settings className="w-8 h-8" />, name: 'Marketplace APIs' },
  { icon: <Upload className="w-8 h-8" />, name: 'ERP Systems' },
  { icon: <Download className="w-8 h-8" />, name: 'Analytics Tools' },
  { icon: <GitBranch className="w-8 h-8" />, name: 'CRM Systems' },
];