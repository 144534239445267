interface SelectProps {
    value: string;
    onChange: (value: string) => void;
    options: Array<{ label: string; value: string }>;
    label?: string;
  }
  
  export const Select: React.FC<SelectProps> = ({ value, onChange, options, label }) => {
    return (
      <div className="space-y-1">
        {label && (
          <label className="text-sm text-gray-500">{label}</label>
        )}
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full rounded-md border border-gray-300 py-2 px-3 bg-white"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };