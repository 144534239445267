// src/components/TransformationsPage.tsx
import React, { useState } from 'react';
import { Select } from './ui/Select';
import { DataTransformation } from './DataTransformationEditor';
import { mockConnections } from '../mockData'; // Assicurati di avere questo nel tuo mockData

export const TransformationsPage: React.FC = () => {
  const [selectedConnection, setSelectedConnection] = useState<string>('');

  return (
    <div className="p-6 space-y-8">
      {/* Connection Selector */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Select Connection</h2>
        <Select
          value={selectedConnection}
          onChange={(value: string) => setSelectedConnection(value)}
          options={mockConnections.map(conn => ({
            label: `${conn.sourceName} → ${conn.destinationName}`,
            value: conn.id
          }))}
          label="Connection"
        />
      </div>

      {/* Transformation Section */}
      {selectedConnection && (
        <DataTransformation
          sourceData={mockConnections.find(c => c.id === selectedConnection)?.sourceName}
          destinationType={mockConnections.find(c => c.id === selectedConnection)?.destinationName}
          onChange={(config) => console.log('Transformation config:', config)}
        />
      )}
    </div>
  );
};