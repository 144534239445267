import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from './LoadingOverlay';

interface DemoButtonProps {
  className?: string;
  children: React.ReactNode;
}

export const DemoButton: React.FC<DemoButtonProps> = ({ className, children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // Prevenire lo scroll quando il loading è attivo
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isLoading]);

  const handleClick = async () => {
    setIsLoading(true);
    // Quando attiviamo il loading, assicuriamoci che il body non sia scrollabile
    document.body.style.overflow = 'hidden';
    await new Promise(resolve => setTimeout(resolve, 3000));
    navigate('/app/sources');
  };

  return (
    <>
      <button
        onClick={handleClick}
        disabled={isLoading}
        className={className}
      >
        {children}
      </button>
      {isLoading && <LoadingOverlay />}
    </>
  );
};