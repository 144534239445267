import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { warehouseConfigs } from '../mockData';
import { SafeImage } from './common/SafeImage';

export const WarehouseConfigPage: React.FC = () => {
  const { warehouseId } = useParams<{ warehouseId: string }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);

  const warehouseConfig = warehouseConfigs[warehouseId || ''];

  if (!warehouseConfig) {
    return (
      <div className="p-6">
        <div className="text-red-500">Warehouse not found</div>
        <button
          onClick={() => navigate('/app/destinations/new')}
          className="mt-4 text-blue-500 hover:text-blue-600"
        >
          ← Back to destinations
        </button>
      </div>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      console.log('Submitted warehouse configuration:', formData);
      navigate('/app/destinations');
    } catch (error) {
      console.error('Configuration failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <div className="mb-8">
        <button
          onClick={() => navigate('/app/destinations/new')}
          className="text-blue-500 hover:text-blue-600 mb-4"
        >
          ← Back to destinations
        </button>
        
        <div className="flex items-center space-x-3 mb-4">
          <SafeImage
            src={warehouseConfig.logo}
            alt={warehouseConfig.name}
            className="w-12 h-12 object-contain"
            fallbackClassName="w-12 h-12"
          />
          <h1 className="text-2xl font-bold">{warehouseConfig.name}</h1>
        </div>
        
        <p className="text-gray-600">{warehouseConfig.description}</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {warehouseConfig.fields.map(field => (
          <div key={field.name}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {field.label}
              {field.required && <span className="text-red-500 ml-1">*</span>}
            </label>
            
            {field.type === 'select' ? (
              <select
                required={field.required}
                value={formData[field.name] || ''}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  [field.name]: e.target.value
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select an option</option>
                {field.options?.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={field.type}
                required={field.required}
                placeholder={field.placeholder}
                value={formData[field.name] || ''}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  [field.name]: e.target.value
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            )}
            
            {field.description && (
              <p className="mt-1 text-sm text-gray-500">{field.description}</p>
            )}
          </div>
        ))}

        <div className="pt-4">
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isLoading ? 'Configuring...' : 'Configure Warehouse'}
          </button>
        </div>
      </form>
    </div>
  );
};