import React from 'react';

export const ConnectorDiagram: React.FC = () => {
  return (
    <div className="relative w-full h-auto p-6 bg-gray-900 text-white rounded-lg shadow-lg">
      <h3 className="text-lg font-bold mb-6">Configure Data Source</h3>
      <div className="space-y-6">

        {/* Configurazione per PostgreSQL */}
        <div className="p-4 bg-gray-800 rounded-lg border border-gray-700">
          <h4 className="text-sm font-semibold mb-4">PostgreSQL</h4>
          <div className="space-y-3">
            <div>
              <label className="block text-xs text-gray-400 mb-1">Host</label>
              <input
                type="text"
                value="localhost"
                readOnly
                className="w-full p-2 bg-gray-700 rounded text-gray-300 text-sm"
              />
            </div>
            <div>
              <label className="block text-xs text-gray-400 mb-1">Port</label>
              <input
                type="number"
                value={5432}
                readOnly
                className="w-full p-2 bg-gray-700 rounded text-gray-300 text-sm"
              />
            </div>
            <div>
              <label className="block text-xs text-gray-400 mb-1">Database</label>
              <input
                type="text"
                value="my_database"
                readOnly
                className="w-full p-2 bg-gray-700 rounded text-gray-300 text-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
