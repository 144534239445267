import { motion } from 'framer-motion';
import { Header } from './Header';
import React, { useEffect } from 'react';


export const ContactUsPage: React.FC = () => {
  useEffect(() => {
    // Scrolla sempre in alto quando la pagina viene caricata
    window.scrollTo(0, 0);
  }, []);
    return (
      <div className="bg-gray-900 min-h-screen text-white">
          <Header/>
        <main className="w-full mx-auto p-6 pt-10"></main>
        <div className="min-h-screen bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="max-w-3xl mx-auto text-center"
            >
              <h1 className="text-4xl font-bold text-white mb-6">
                Get in Touch
              </h1>
              <p className="text-xl text-gray-300 mb-8">
              Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible.
              </p>
              <div className="bg-gray-800/50 rounded-xl border border-gray-700 p-8 text-left">
                <h2 className="text-2xl font-bold text-white mb-4">
                    Our Contacts:
                </h2>
                <div className="space-y-4">
                  <ul className="list-disc list-inside text-gray-300 space-y-2">
                    <li>E-mail: wagane.diame@gmail.com</li>
                    <li>Call Us: +39 331 1398 791</li>
                    <li>Business Hours: Mon-Fri 9:00 AM to 6:00 PM</li>
                  </ul>
                </div>
                <div className="mt-8 p-6 bg-gray-900/50 rounded-lg">
                  <p className="text-gray-300 text-lg">
                    Directly send en email by clickin the button down below!
                  </p>
                  <a 
                    href="mailto:wagane.diame@gmail.com"
                    className="inline-block mt-4 px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
                  >
                    Get in Touch →
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    );
  };